/**
 * @typedef {import("../../types/index").UpsertComplianceRulesConfig} UpsertComplianceRulesConfig
 * @typedef {import("../../types/index").Tag} Tag
 */

import instance from './';
import config from '../../config';

const { iamUrl, accountsUrl, commonAccountsUrl } = config;

export class AccountsService {
    static baseRoute = 'accounts';
    static controller = new AbortController();

    static abort() {
        this.controller.abort();
    }

    static async getAccounts() {
        const res = await instance.get(`${accountsUrl}/admin/account`);
        return res.data;
    }

    static async getAllRegions() {
        const res = await instance.get(`${accountsUrl}/admin/account/regions`);
        return res.data;
    }

    static async saveUserInDB(id, networkID) {
        const res = await instance.post(`${accountsUrl}/admin/user/activate`, { id, networkID });
        return res.data;
    }

    static async create(account) {
        const res = await instance.post(`${accountsUrl}/admin/account`, account);
        return res.data;
    }

    static async updateAccountLicenses(products) {
        const res = await instance.put(`${accountsUrl}/admin/update-account-licenses`, products);
        return res.data;
    }

    static async update(account) {
        const res = await instance.put(`${accountsUrl}/admin/account`, account);
        return res.data;
    }

    static async handleAccountStatus(account) {
        const res = await instance.put(`${accountsUrl}/admin/account/update_status/${account.networkId}`, account);
        return res.data;
    }

    static async getAccountsSupporters() {
        const res = await instance.get(`${accountsUrl}/admin/account/supporters`);
        return res.data;
    }

    static async addUserToAccountSupporters(userInfo) {
        const res = await instance.post(`${accountsUrl}/admin/account/supporters`, userInfo);
        return res.data;
    }

    static async editAccountSupporter(userInfo) {
        const res = await instance.put(`${accountsUrl}/admin/account/supporters`, userInfo);
        return res.data;
    }

    static async deleteAccountSupporter(id) {
        await instance.delete(`${accountsUrl}/admin/account/supporters?id=${id}`);
    }

    static async sendAdminActivationEmail(params) {
        await instance.post(`${iamUrl}/user/send_activation_mail`, params);
    }

    static async updateMembersOnIAM(account) {
        const res = await instance.post(`${iamUrl}/organization`, {
            prevEmails: [],
            emails: [],
            orgId: account.orgId,
        });
        return res.data;
    }

    static async getDataLocations() {
        const res = await instance.post(`${this.baseRoute}/get_data_locations`);
        return res.data;
    }

    static async getProductTypes() {
        const res = await instance.post(`${this.baseRoute}/get_product_types`);
        return res.data;
    }

    static async getAccountTypes() {
        const res = await instance.post(`${this.baseRoute}/get_account_types`);
        return res.data;
    }

    static async getBundlePlans() {
        const res = await instance.post(`${this.baseRoute}/get_bundle_plans`);
        return res.data;
    }

    static async getMediaSpendTiers() {
        const res = await instance.post(`${this.baseRoute}/get_media_spend_tiers`);
        return res.data;
    }

    static async getAssociatedNetworks(params) {
        const res = await instance.post(`${this.baseRoute}/get_associated_networks`, params);
        return res.data;
    }

    static async resetInternalAdminPassword(params) {
        const res = await instance.post(`${this.baseRoute}/reset_internal_admin_password`, params);
        return res.data;
    }

    static async getAccountInternalAdmin(params) {
        const res = await instance.post(`${this.baseRoute}/get_account_internal_admin`, params);
        return res.data;
    }

    static async updateIntegrationsLicense(params) {
        const res = await instance.post(`${this.baseRoute}/update_integrations_license`, params);
        return res.data;
    }

    /**
     *
     * @param {number} networkId
     * @param {UpsertComplianceRulesConfig} params
     * @returns {Promise<void>}
     */
    static async upsertComplianceRules(networkId, params) {
        const res = await instance.put(`${commonAccountsUrl}/configuration/compliance/${networkId}`, params);
        return res?.data;
    }

    /**
     *
     * @param {number} networkId
     * @returns {Promise<Array<Tag>>}
     */
    static async getAllTags(networkId) {
        const res = await instance.get(`${commonAccountsUrl}/tag/${networkId}`, { signal: this.controller.signal });
        return res?.data;
    }
}
